import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class xA extends React.Component {

  render() {

    const pageTitle = "xA (Expected Assists)"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const mxg = Math.abs(mdata.filter(d => d.node.competition === "League Since 14").map(d => d.node.xac))
    const rxg = Math.abs(rdata.filter(d => d.node.competition === "League Since 14").map(d => d.node.xac))

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => (d.node.assists - d.node.xa))[0]).toFixed(1)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => (d.node.assists - d.node.xa))[0]).toFixed(1)
      )
    }

    const comps = [
      {
        "comp": "League (Since 14/15)",
        "mSubStat": subStat('m', 'League Since 14'),
        "rSubStat": subStat('r', 'League Since 14'),
        "btnText": "Season Breakdown"
      }
    ]

    return (

      <Layout>
        <SEO
          title={`xA (Expected Assists) - Messi vs Ronaldo xA Stats`}
          description={`xA is the number of assists a player should have based on the quality of chances they have created - how do Messi and Ronaldo compare on this front?`}
          canonicalPath={`/detailed-stats/xa-expected-assists/`}
        />

        <h1>xA (Expected Assists) <span className="sr-only">Messi vs Ronaldo xA Stats</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-4">
          <InfoBar classes="w-full justify-center" msg="Non-European league stats not included in totals" />
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              title={`League xA 14/15 - 22/23`}
              playerTitleHidden={`${d.comp} xA 14/15 - 22/23`}
              statType="xA (Expected Assists)"
              btnText=''
              statView={['Totals', 'Actual Assists']}
              mSubStat={`${d.mSubStat.toString() !== "NaN" ? Math.abs(d.mSubStat) : '-'} ${Number(d.mSubStat) > 0 ? 'more' : 'fewer'} assists than he "should" have`}
              rSubStat={`${d.rSubStat.toString() !== "NaN" ? Math.abs(d.rSubStat) : '-'} ${Number(d.rSubStat) > 0 ? 'more' : 'fewer'} assists than he "should" have`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? Number(d.mSubStat) : '-', d.rSubStat.toString() !== "NaN" ? Number(d.rSubStat) : '-','lowWins')}
              since="14/15"
            />
          ))}

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {competition: {eq: "League Since 14"}}) {
      edges {
        node {
          competition
          xac
          apps
          minsPlayed
          assists
          xa
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {competition: {eq: "League Since 14"}}) {
      edges {
        node {
          competition
          xac
          apps
          minsPlayed
          assists
          xa
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
          xa
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
          xa
        }
      }
    }
  }
`

export default xA
